.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
}
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 500%;

}

.marker {
  background-image:url('mapbox-icon.png');
  background-size: cover;
  width:50px;
  height:50px;
  border-radius:50%;
  cursor:pointer;
}

.mapboxgl-popup {
  max-width:200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'open Sans', sans-serif;
}

.map-container {
  height: 400px;
  margin: 100;

  }

  body {
    background-color:#F8F9F9  ; /* Set your desired background color here */
    margin: 0; /* Optional: Remove default body margin */
    padding: 0; /* Optional: Remove default body padding */
  }

  
  
